
const HomeCard = ({ image, title, messages, blurb }) =>  {
        return (
            <figure className="pseudo-parallax-card-container">
                <div className="mock top left"></div>
                <div className="mock top right"></div>
                <div className="mock bottom left"></div>
                <div className="mock bottom right"></div>

                <div className="content-container">
                    <div className="message-container">
                        {messages.map(function(message, i) {
                            return <p className="message" key={message} >{message}</p>
                            }
                        )}
                        <div className="contentCont">
                            <h2>Hey There, <span>{title}</span></h2>
                            <img src={image} alt="Profile"/>
                            <p>{blurb}</p>
                        </div>
                    </div>
                </div>
            </figure>
        )
    
}

export default HomeCard