import { useState, useEffect } from 'react';
import NavItem from './NavItem';
import './Navbar.scss';
import headshot from '../../img/Profile_Img.jpeg';
import { NavData } from '../../Helpers/NavData';

const Navbar = () => {
    const [ activeNavItem, setActiveNavItem ] = useState('Home')
    const [ mobileOpen, setMobileOpen ] = useState(false)

    useEffect(() => {
        document.getElementById(activeNavItem).classList.add('active')
      });

    const toggleNav = () => {
        mobileOpen === false ? setMobileOpen(!mobileOpen) : setMobileOpen(false);
    }

    const activeItem = (x) => {
        setMobileOpen(false)
        if(activeNavItem.length > 0){
            document.getElementById(activeNavItem).classList.remove('active');
        }
        setActiveNavItem(x)
        document.getElementById(x).classList.add('active');
    };

        return (
            <div className='navCont'>
                <div className='navToggle'>
                    <i onClick={toggleNav} className="fas fa-bars"></i>
                </div>
                <nav className={mobileOpen === true ? "navbar mobileOpen" : 'navbar'}>
                    <img src={headshot} alt='Lance Avitia'></img>
                    <ul>
                        {NavData.map(item => {
                            return (
                                <NavItem key={item.item} logo={item.logo} item={item.item} tolink={item.tolink} activec={activeItem} />
                            )
                        })}
                    </ul>
                </nav>
                
            </div>
        )
    }


export default Navbar