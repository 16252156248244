import { Link } from 'react-router-dom';

const NavItem = (props) => {
    return (
        <li id={props.item}>
            <Link data={props.item} to={props.tolink} onClick={props.activec.bind(this, props.item)}>
                <i className={props.logo}></i>
            </Link>
        </li>
    )
}


export default NavItem