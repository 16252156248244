import React, { Component } from 'react';
import AboutInner from '../components/About/AboutInner';

class About extends Component {
    render() {
        return (
            <div className='content_container'>
                <AboutInner />
            </div>
        )
    }
}

export default About