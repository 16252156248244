import React, { Component } from 'react';
import SkillsInner from '../components//Skills/SkillsInner'

class Skills extends Component {
    render() {
        return (
            <div className='content_container skills'>
                <SkillsInner />
            </div>
        )
    }
}

export default Skills