const hobbies = 'https://images.unsplash.com/photo-1597914377769-db5167cb0221?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=634&q=80';
const aboutMe = 'https://images.unsplash.com/photo-1493612276216-ee3925520721?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=700&q=80';
const devJourney = 'https://images.unsplash.com/photo-1506452819137-0422416856b8?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=966&q=80';
const tomorrow = 'https://images.unsplash.com/photo-1489533119213-66a5cd877091?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1051&q=80';

export const AboutData = [
    {
        title: "Who Am I?",
        image: aboutMe,
        blurb: "Hello, I'm a freelance Web Developer that took the lost year of covid to learn a skill that I could pursue as my career. Before, I was a Utility Planner. A job that includes hard set deadlines, collaboration between teams / clients, and a lot riding on your individual work. But, when we all had to start staying inside... I got curious for something new. I wanted something equally fast paced but with a little more artistic flair. Something that had equal amounts creating as it did assembling. And I found it in Web Development."
    },
    {
        title: "How I Learned",
        image: devJourney,
        blurb: "I'm an avid reader. And, as such, that was my go to for the start of my education. I dove in. Learning the basics: Html, CSS, and Javascript. Coming out hungry for more and a little upset with myself for not starting this journey sooner. Online resources followed, eventually bringing me to an online bootcamp. Completing it was a turning point. I went in with the basics and came out with all the tools I would need to start creating. So that's what I did. Using a variety of different frameworks and stacks, I built portfolio pieces until it became second nature. Now, I am still learning. But that's a feature. Not a bug."
    },
    {
        title: "What Can I do for you?",
        image: tomorrow,
        blurb: "That's the question that brought you here, right? It's a fair question. The truth is simple, I'm here to help you. Wether you have a revolutionairy web app idea or want to share your grandmother's recipes. I have the skills to help and would love to be involved in your success. LanceA@lanceadev.com"
    },
    {
        title: "Keeping Balance",
        image: hobbies,
        blurb: "I believe the best way to excel in what you do is to keep balance. I keep a daily routine, I stay fit, I walk my dog twice a day, and I enjoy an occasional weekend trip to some off trail place I havent seen before. Not to 'recharge' after work, but as another facet of my time. Living. Working. Creating. For me, keeping balance is the way to bring the best out of each. It's what I strive to do. And I happen to love what I do."
    },
]