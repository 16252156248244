import React from 'react';
// import { Link } from 'react-router-dom';
import './Social.scss';

const Social = () => {

    const copyToClipboard = () => {
        navigator.clipboard.writeText('LanceADev@gmail.com');
        let emailEl = document.querySelector('.email');
        emailEl.setAttribute("tooltip", "Copied To Clipboard");
        setTimeout(() => emailEl.setAttribute("tooltip", "Copy Email"), 1000)
    }

    return (
        <footer className="social">
            <div className="socialItem email" tooltip="Copy Email" onClick={copyToClipboard}><i className="fas fa-envelope"></i></div>
            <a className="socialItem" tooltip="Github" href="https://github.com/Lance77a" target="_blank" rel="noopener noreferrer"><i className="fab fa-github"></i></a>
            <a className="socialItem" tooltip="Linked In" href="https://www.linkedin.com/in/lance-avitia-73a54138/" target="_blank" rel="noopener noreferrer"><i className="fab fa-linkedin"></i></a>
            {/* <Link className="socialItem" tooltip="My Resume" to="/csv" >
                <a><i className="fas fa-file-csv"></i></a>
            </Link> */}
        </footer>
    )
}


export default Social