import React, {Component} from 'react';
import './SkillsInner.scss';
import SkillsSlider from './SkillsSlider';
import { SkillsData } from '../../Helpers/SkillsData';

class SkillsInner extends Component {
    render() {
        return (
            <div className="skillsInner">
                {SkillsData.map(function(slide, i) {
                    return <SkillsSlider key={i} side={slide.side} icon={slide.icon} tags={slide.tags} title={slide.title} desc={slide.desc} />;
                })}
               </div>
        )
    }

}

export default SkillsInner