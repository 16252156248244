import React, { Component } from 'react';
import './AboutInner.scss';
import { AboutData } from '../../Helpers/AboutData'
import AboutCard from './AboutCard';


class AboutInner extends Component {
    render () {
        return (
            <div className="aboutInner">
                { AboutData.map(function(card, index) {
                    return <AboutCard key={index} title={card.title} image={card.image} blurb={card.blurb} />
                })}
            </div>
        )
     }
}

export default AboutInner