import React, { Component } from 'react';
import './SkillsSlider.scss';

class SkillsSlider extends Component {
    render() {
        return (
            <div className={this.props.side}>
                <i className={this.props.icon}></i>
                <div className="slide">
                    <div className="slideTitle">
                        <h2>{this.props.title}</h2>
                        <ul>
                            {this.props.tags.map(function(tag, i) {
                                return <li key={i}>{tag}</li>;
                             })}
                        </ul>
                    </div>
                    <p>{this.props.desc}</p>
                </div>
            </div>
        )
    }
}

export default SkillsSlider