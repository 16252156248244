import React, { useState } from 'react';

const AboutCard = (props) => {
    const [cardFocus, setFocus] = useState(false);
  
    const openFocus = () => {
      cardFocus === false && setFocus(!cardFocus);
    };

    const closeFocus = () => {
        cardFocus !== false && setFocus(false)
    }

    return (
        <div onClick={openFocus} className='aboutCard'>
            <div className={cardFocus ? 'cardInner cardFocus' : 'cardInner'}
            style={{ backgroundImage: `url(${props.image})`}}>
                <div className='cardContent'>
                    <h2>{props.title}</h2>
                    <p>{props.blurb}</p>
                    <i onClick={closeFocus} className="far fa-times-circle"></i>
                </div>
            </div>
        </div>
    )
}


export default AboutCard