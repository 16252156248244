import { useState } from 'react';
import './Carousel.scss';
import { images } from '../../Helpers/CarouselData';

const Carousel = () => {
    const [ currentImg, setCurrentImg ] = useState(0);

    return (
        <div className="carousel">
            <div className="title">
                <a href={images[currentImg].href} target="_blank" rel="noopener noreferrer">
                    <h2>{images[currentImg].title}</h2>
                    <i className="fas fa-link"></i>
                </a>
                <p>{images[currentImg].stack}</p>
            </div>
            <div className="carouselInner"
                 style={{ backgroundImage: `url(${images[currentImg].image})` }}>
                    <div className="arrow leftArrow" onClick={() => currentImg > 0 && setCurrentImg(currentImg - 1)} >
                        <i className="fas fa-chevron-left"></i></div>
                    <div className="arrow rightArrow" onClick={() => currentImg < images.length - 1 && setCurrentImg(currentImg + 1)} >
                        <i className="fas fa-chevron-right"></i></div>
            </div>
            <div className="cInfo">
                <p className="blurb">{images[currentImg].info}</p>
            </div>
        </div>
    )
}

export default Carousel