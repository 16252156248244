import React from 'react';
import './App.scss';
import { BrowserRouter as Router, Route, } from 'react-router-dom';

import Navbar from './components/Navbar/Navbar';
import Social from './components/Social/Social';
import Home from './contents/Home';
import About from './contents/About';
import Portfolio from './contents/Portfolio';
import Skills from './contents/Skills';
import Hero from './components/Hero/Hero'

// import CSV from './contents/CSV'

function App() {
  return (
    <Router>
        <div className="App">
          <Navbar />

              <Hero>
                  <Route exact path="/">
                    <Home />
                  </Route>

                  <Route exact path="/about">
                    <About />
                  </Route>

                  <Route exact path="/portfolio">
                    <Portfolio />
                  </Route>

                  <Route exact path="/skills">
                    <Skills />
                  </Route>

                  {/* <Route exact path="/csv">
                    <CSV />
                  </Route> */}
              </Hero>

          <Social />
        </div>
    </Router>
  );
}

export default App;
