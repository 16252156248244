import './Hero.scss'

const Hero = ({children}) => {
    return (
        <main className="hero">
            {children}
        </main>
    )
}

export default Hero