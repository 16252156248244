import React, { Component } from 'react';
import HomeInner from '../components/Home/HomeInner';

class Home extends Component {
    render() {
        return (
            <div className='content_container'>
                <HomeInner />
            </div>
        )
    }
}

export default Home