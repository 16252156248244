import React, { Component } from 'react';
import Carousel from '../components/Portfolio/Carousel';



class Portfolio extends Component {
    render() {
        return (
            <div className='content_container'>
                <Carousel />
            </div>
        )
    }
}

export default Portfolio