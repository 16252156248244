import Puget_Blue from '../img/puget_blue.png';
import Cup_And_Cone from '../img/cup_&_cone.png';
import Qrates_Replica from '../img/qrates_replica.png';
import RiotVan_Gaming from '../img/riotvan_gaming.png';
import Bean_Bag_Coffee from '../img/Bean_Bag_Coffee.png';

export const images = [
    {
        image: Puget_Blue,
        title: "Puget Blue",
        info: "Puget Blue is a perfect Landing page for a small business in need of an online presence. It tells a potential customer exactly who they are and what their brand has to offer them. It's fast, SEO optimal, cheap to host, and responsive for all screens.",
        stack: "Static / HTML / CSS",
        href: "https://lance77a.github.io/Puget_Blue/index.html"
    },
    {
        image: Cup_And_Cone,
        title: "Cup & Cone",
        info: "A server side rendered(SSR) site for a fictional Ice Cream pop-up. The Node.js backend is ready for whatever feature is required. EJS templating and SASS / Bootstrap are utilized for a cleaner codebase and quicker development. This project can be easily connected to a CMS of your choosing and be ready to go.",
        stack: "SSR Node / EJS Templating / Bootstap",
        href: "https://murmuring-crag-20562.herokuapp.com/"
    },
    {
        image: Qrates_Replica,
        title: "Qrates",
        info: "Do you have a design in mind? Full design plans that you commissioned? A wireframe? Rough drawing on a napkin? I built this project with you in mind. This project is a clone of Qrates.com's landing page. Using it as a visual reference / wireframe.",
        stack: "React / Next.js / SASS",
        href: "https://qrates-re-create.vercel.app/"

    },
    {
        image: Bean_Bag_Coffee,
        title: "Bean Bag Coffee",
        info: "A production ready Ecommerce site using Stripe for product creation and secure checkout. With Next.js on the front end providing a snappy mobile app feel with static page generation for optimal SEO practices.",
        stack: "React / Next.js / Stripe API",
        href: "https://coffee-ecom.vercel.app/"

    },
    {
        image: RiotVan_Gaming,
        title: "Riot Van Gaming",
        tagline: "Need a place to share your throughts? Have wisdom that needs to be shared? Maybe a family recipe you're ready to share with the world?",
        info: "This Netlify fed blog site using Svelte/Sapper is an eloquent and versatile solution to the question, 'Do I want to deal with my own site?'. Netlify hosts and provides an admin dashboard directly on your site for you to write and post as desired.",
        stack: "Svelte / SASS / Netlify CMS",
        href: "https://unruffled-almeida-757da1.netlify.app/"
    }
]