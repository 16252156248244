import './HomeInner.scss';
import { HomeData } from '../../Helpers/HomeData';
import HomeCard from './HomeCard';

const HomeInner = () => {

    return (
        <div className="homeInner">
            <HomeCard image={HomeData[0].image} title={HomeData[0].title} messages={HomeData[0].messages} blurb={HomeData[0].blurb} />
        </div>
    )

}

export default HomeInner