export const NavData = [
    {
        logo: "fas fa-house-user",
        item: "Home",
        tolink: "/"
    },
    {
        logo: "fas fa-portrait",
        item: "About",
        tolink: "/about"
    },
    {
        logo: "fas fa-scroll",
        item: "Portfolio",
        tolink: "/portfolio"
    },
    {
        logo: "fas fa-toolbox",
        item: "Skills",
        tolink: "/skills"
    },

]