export const SkillsData = [
    {
        title: "HTML",
        icon: "fab fa-html5 skillIcon",
        tags: ["EJS", "JSX", "Markdown"],
        desc: "I'm more than familiar with HTML and various templating syntaxes that add a bit more functionality. Such as JSX and EJS. Plus, experience parsing and rendering Markdown for documention or blog posts.",
        side: "skillsSlider leftBar"
    },
    {
        title: "React",
        icon: "fab fa-react skillIcon",
        tags: ["SPA", "Next.js", "Components"],
        desc: "I've built majority of my portfolio with React (this site being one of them.) and use Next.js whenever suitable. Wether you need a front end to a stack (MERN), or would like Next.js to do the API lifting, I'm skillful with them both.",
        side: "skillsSlider rightBar"
    },
    {
        title: "CSS",
        icon: "fab fa-css3-alt skillIcon",
        tags: ["SASS", "Bootstrap", "Flexbox"],
        desc: "Building mobile ready, responsive pages is a passion of mine. I love CSS for it's 'math is math' nature. I've honed my skills with flexbox, grid, and a little help from SASS to a dependable edge.",
        side: "skillsSlider leftBar"
    },
    {
        title: "Node.js",
        icon: "fab fa-node-js skillIcon",
        tags: ["Express", "SSR", "API Calls"],
        desc: "API calls, Server Side Rendering, Authentication. It's become pertinent for front end developer's to learn Node. As such, I'm just as comfortable building RESTful, database connected API's as I am fetching from them.",
        side: "skillsSlider rightBar"
    },
    {
        title: "Javascript",
        icon: "fab fa-js skillIcon",
        tags: ["Frameworks", "Fetch Requests", "jQuery"],
        desc: "Promises, Arrow Functions, Loops, Async / Await, the ambiguous 'This'. I can confidently read and write Javascript. Asuring DRY code and the use of best practies throughout my work.",
        side: "skillsSlider leftBar"
    },
    {
        title: "Git",
        icon: "fab fa-git skillIcon",
        tags: ["VCS", "Github", "Gitlab"],
        desc: "Version Control, a sometimes overlooked part of a developer's pipeline. Can slow development down to a crawl. Pulling a project, editing, and merging it back in for production is just another tool in my belt.",
        side: "skillsSlider rightBar"
    }
]